import CurrentUserApi from '~/api/current-user'
import AuthApi from '~/api/auth'
import AjaApi from '~/api/aja'
import CatalogApi from '~/api/catalog'
import BrandApi from '~/api/brand'
import MainApi from '~/api/main'
import ShoppingCenterApi from '~/api/shopping-center'
import CollectionApi from '~/api/collection'
import ProductApi from '~/api/product'
import CartApi from '~/api/cart'
import FavoriteApi from '~/api/favorite'
import BuyerAccountApi from '~/api/buyer-account'
import JewelryApi from '~/api/jewelry'
import WatchesApi from '~/api/watches'
import SouvenirApi from '~/api/souvenir'
import OfferApi from '~/api/offer'
import UserApi from '~/api/user'
import ServicesApi from '~/api/services'
import preOrderApi from '~/api/pre-order'

export const combineAPI = ($axios) => {

  return {
    currentUser: CurrentUserApi($axios),
    auth: AuthApi($axios),
    aja: AjaApi($axios),
    catalog: CatalogApi($axios),
    brand: BrandApi($axios),
    main: MainApi($axios),
    shoppingCenter: ShoppingCenterApi($axios),
    collection: CollectionApi($axios),
    product: ProductApi($axios),
    cart: CartApi($axios),
    favorite: FavoriteApi($axios),
    buyerAccount: BuyerAccountApi($axios),
    jewelry: JewelryApi($axios),
    watches: WatchesApi($axios),
    souvenir: SouvenirApi($axios),
    offer: OfferApi($axios),
    user: UserApi($axios),
    services: ServicesApi($axios),
    preOrder: preOrderApi($axios)
  }
}
