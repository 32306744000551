import { getQuery } from '~/core/utils/queryAPI'

export default $axios => ({
  async global () {
    const { data } = await $axios.get('/rate/global')
    return data
  },

  async globalTest () {
    const { data } = await $axios.get('/rate/rates-global')
    return data
  },

  async local () {
    try {
      const { data } = await $axios.get('/rate/local?month=1');
      return data;
    } catch (error) {
      console.error('Request failed after multiple retries:', error);
      throw error;
    }
  },

  async localTest () {
    const { data } = await $axios.get('/rate/rates-test?month=1')
    return data
  },

  async history (body) {
    const { data } = await $axios.get(
      `/rate/local_history/${body.purityType}?${getQuery(body.query)}`
    )
    return data
  },

  async cba () {
    const { data } = await $axios.get('/rate/cba')
    return data
  }
})
