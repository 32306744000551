export default {
  state: () => {
    return {
      requestsRunning: 1,
      loaderEnabled: true,
      globalFilters: [],
      currency: 'am',
      activeProductId: null,
      sliderCount: 1,
      activeRoutePath: false,
      isModal: null, // check-email, login, forgot-password, adress, address
      isMobFilter: false,
      isMobMenu: false,
      isShowBasket: false,
      isJobs: undefined,
      alert: {
        message: '',
        type: ''
      }
    }
  },
  mutations: {
    toggleMenu (state) {
      state.isMobMenu = !state.isMobMenu
    },
    setAlert (state, payload) {
      state.alert = payload
    },
    setShowBasket (state, payload) {
      state.isShowBasket = payload
    },
    setModal (state, payload) {
      state.isModal = payload
    },
    setIsJobs (state, payload) {
      state.isJobs = payload
    },
    setMobFilter (state, payload) {
      state.isMobFilter = payload
    },
    setCurrency (state, payload) {
      state.currency = payload
    },
    setGlobalFilters (state, payload) {
      state.globalFilters = payload
    },
    setActiveProductId (state, payload) {
      state.activeProductId = payload
    },
    setActiveRoutePath (state, payload) {
      state.activeRoutePath = payload
    },
    setSliderCount (state, payload) {
      state.sliderCount = payload
    },
    SET_REQUEST_STARTED (state) {
      state.requestsRunning += 1
    },
    SET_LOADER_STATE (state, loaderState) {
      state.loaderEnabled = loaderState
    },
    SET_REQUEST_FINISHED (state) {
      if (state.requestsRunning > 0) {
        state.requestsRunning -= 1
      }
    }
  },
  getters: {
    activeCurrency(state) {
      return state.currency;
    }
  },
  namespaced: true
}
